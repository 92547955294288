import React from "react";

const VideoComponent = ({ videoRef }) => {
  return (
    <iframe
      className="videoPlayer"
      title="Youtube player"
      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
      src={`https://youtube.com/embed/${videoRef}?autoplay=0`}
    ></iframe>
  );
};

export default VideoComponent;
