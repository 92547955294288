import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import styled from "styled-components";

const ThreeJSComponent = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const currentMountRef = mountRef.current;

    // Scene setup
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xfffdc4);

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const camOffset = 20;
    camera.position.set(camOffset, 20, camOffset); // Set a different initial position to better see the rotation

    // Lights setup
    const light = new THREE.DirectionalLight(0xffffff, 5);
    light.position.set(20, 20, -20).normalize();
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    camera.add(light); // Attach the directional light to the camera
    scene.add(camera);
    scene.add(ambientLight);

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    currentMountRef.appendChild(renderer.domElement);

    // OrbitControls setup
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.autoRotate = false; // Disable auto-rotation since we'll handle it manually

    // Load 3D model
    let flower;
    const scale = 6.5;
    const modelLoader = new GLTFLoader();
    modelLoader.load(
      "/sunfloVR_3dModel/sunfloVR_rigged.gltf", // Update this path to your actual model file path
      (gltf) => {
        flower = gltf.scene;
        scene.add(flower);
        flower.position.set(0, -24, 0);
        flower.scale.set(scale, scale, scale);
        flower.rotation.set(0, 0, 0);
      }
    );

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    const rotationSpeed = 0.0005; // Adjust this value for rotation speed
    function animate() {
      requestAnimationFrame(animate);

      // Manually update camera position to rotate around Y-axis
      camera.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotationSpeed);
      camera.lookAt(new THREE.Vector3(0, 0, 0)); // Ensure camera looks at the center of the scene

      render();
    }

    function render() {
      renderer.render(scene, camera);
    }

    animate(); // Start the animation loop

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      if (currentMountRef) {
        currentMountRef.removeChild(renderer.domElement);
      }
    };
  }, []);

  return <Container ref={mountRef} />;
};

export default ThreeJSComponent;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh; /* Full viewport height */
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Light grey background */
  margin: 0;
  padding: 0;
`;