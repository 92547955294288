import React from "react";
import "./MainPage.css";

//Components
import ThreeJSComponent from "../Components/ThreeJSComponent";
import OverlayUI from "../Components/OverlayUI";

function MainPage() {
  return (
    <div className="main-page">
      <ThreeJSComponent />
      <OverlayUI />
    </div>
  );
}

export default MainPage;
