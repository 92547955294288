
import './App.css';
import MainPage from './Pages/MainPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>

      <Routes>
        <Route path="*" element={<MainPage />} />
        {/* Add more routes here */}
      </Routes>
    </Router>
  );
}

export default App;