import React from "react";

export default function ServicesSection() {
  return (
    <div className="mySection">
      <h3 className="serviceTitle">
        We <span>LOVE</span> <br></br> to make...
      </h3>
      <div className="ServicesContainer">
        <div className="ServicesTextWrap">
          <h3>VR/MR Games</h3>
          <p>
            From concept to release, we can help you with your VR/MR project.
            With multiple successful releases we have the skills to bring your
            project to life.
          </p>
        </div>
        <div className="ServicesTextWrap">
          <h3>Social AR</h3>
          <p>
            With over 200 million combined plays we are experts in the custom AR
            development area. We also have the relevant brand experience to help
            you get your AR project off the ground.
          </p>
        </div>
        <div className="ServicesTextWrap">
          <h3>
            WebAR & <br></br> 3D Websites
          </h3>
          <p>
            If you are looking for a way to stand out with your website or want
            to give a unique user experience with webAR, we can help to build it
            from scratch!
          </p>
        </div>
      </div>
    </div>
  );
}
