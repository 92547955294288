import React from "react";

export default function CoverSection() {
  return (
    <div className="mySection">
      <div className="CoverContainer">
        <h1 className="myTitle">SunfloVR</h1>
        <h2 className="mySubTitle">We develop games of the future.</h2>
      </div>
    </div>
  );
}
