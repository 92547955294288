import React, { useRef } from "react";
import CoverSection from "./CoverSection";
import ServicesSection from "./ServicesSection";
import AboutUsSection from "./AboutUsSection";
import PrevWorkSection from "./PrevWorkSection";
import ContactSection from "./ContactSection";

const OverlayUI = () => {
  const mountRef = useRef(null);

  return (
    <div className="OverlayContainer" ref={mountRef}>
      <CoverSection />
      <ServicesSection />
      <AboutUsSection />
      <PrevWorkSection />
      <ContactSection />
    </div>
  );
};

export default OverlayUI;
