import React from "react";
import VideoComponent from "./VideoComponent";

export default function PrevWorkSection() {
  return (
    <div className="mySection">
      <div className="PrevWorkContainer">
        <div className="VideoContainer">
          <h3 className="prevWorkTitle">Featured work:</h3>
          <VideoComponent videoRef={"NlSKUTbHpMY"} />
        </div>
      </div>
    </div>
  );
}
