import React from "react";

import AndrewPic from "../Media/Andrew.jpg";
import InesPic from "../Media/Ines.jpg";
import TeamMember from "./TeamMember";

const Andrew = {
  pic: AndrewPic,
  name: "Andrew Douglas",
  job: "Programming, Tech lead",
  details: [
    "4+ years of VR development experience",
    "4+ released VR games",
    "Programming experience (VR, Desktop, Mobile, Web)",
    "150+ Million impressions on social AR effects",
    "Oculus start member since 2020",
  ],
  website: "www.weirdeyestudios.com",
  linkedIn: "https://www.linkedin.com/in/andrew-douglas-352841243/",
  instagram: "https://www.instagram.com/weirdeyestudios/",
  x: "https://x.com/WeirdEyeStudios",
};

const Ines = {
  pic: InesPic,
  name: "Ines Hilz",
  job: "Visuals, Art lead",
  details: [
    "B.A. Multimedia | VR Design",
    "4+ years of VR, AR & game development experience",
    "5+ years Blender 3D experience",
    "70+ Million impressions on social AR effects",
    "1 released game on steam",
  ],
  website: "ineshilz.myportfolio.com",
  linkedIn: "https://www.linkedin.com/in/ines-hilz/",
  instagram: "https://www.instagram.com/inidewin_/",
  x: "https://x.com/InesHilz",
};

export default function AboutUsSection() {
  return (
    <div>
      <div className="mySection">
        <div className="AboutUsContainer">
          <div className="Team">
            <TeamMember info={Andrew} />
            <TeamMember info={Ines} />
          </div>
        </div>
      </div>
      <div className="mySection mobileOnly">
        <div className="AboutUsContainer">
          <TeamMember info={Ines} />
        </div>
      </div>
    </div>
  );
}
