import React, { useState, useEffect } from "react";

import linkedIn from "../Media/linkedIn.png";
import insta from "../Media/instagarm.png";
import x from "../Media/twitter.png";

export default function TeamMember({ info }) {
  const [name, setName] = useState(info.name);
  const [job, setJob] = useState(info.job);
  const [pic, setPic] = useState(info.pic);
  const [details, setDetails] = useState(info ? info.details : []);
  const [website, setWebsite] = useState(info.website);
  const [linkedInUrl, setLinkedInUrl] = useState(info ? info.linkedIn : "");
  const [instagramUrl, setInstagramUrl] = useState(info ? info.instagram : "");
  const [twitterUrl, setTwitterUrl] = useState(info ? info.x : "");

  useEffect(() => {
    if (info) {
      setName(info.name);
      setJob(info.job);
      setPic(info.pic);
      setDetails(info.details);
      setWebsite(info.website);
      setLinkedInUrl(info.linkedIn);
      setInstagramUrl(info.instagram);
      setTwitterUrl(info.x);
    } else {
      console.log("Info is undefined");
    }
  }, [info]);

  return (
    <div className="TeamMember">
      <img className="MyImg" src={pic} alt="Ines" />
      <div className="MemberText">
        <h3>{name}</h3>
        <p className="JobDescription">{job}</p>
        <ul>
          {details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
        <p>
          <a href={"https://" + website} target="_blank" rel="noopener noreferrer">{website}</a>
        </p>
      </div>
      <div className="socials">
        <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
          <img src={linkedIn} className="social_1" alt="LinkedIn" />
        </a>

        <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
          <img src={insta} className="social_1" alt="Instagram" />
        </a>

        <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
          <img src={x} className="social_1" alt="Twitter" />
        </a>
      </div>
    </div>
  );
}
