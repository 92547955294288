import React, { useState } from "react";
import tiktok from "../Media/tiktok.svg";
import insta from "../Media/instagarm.png";
import x from "../Media/twitter.png";

export default function ContactSection() {
  // State to track the visibility of the hiddenImpressum div
  const [isImpressumVisible, setIsImpressumVisible] = useState(false);

  // Function to toggle the visibility
  const toggleImpressum = () => {
    setIsImpressumVisible(!isImpressumVisible);
  };

  return (
    <div className="mySection contact">
      <h1>Let's talk!</h1>
      <div className="ContactContainer">
        <a href="mailto:youremail@example.com">
          <h3>hello@sunflovr.com</h3>
        </a>
      </div>
      <div className="ContactContainer">
        <div className="socials">
          <a
            href="https://www.tiktok.com/@sunflo.vr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tiktok} className="social_1" alt="LinkedIn" />
          </a>

          <a
            href="https://www.instagram.com/sunflo.vr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={insta} className="social_1" alt="Instagram" />
          </a>

          <a
            href="https://www.x.com/SunfloVr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={x} className="social_1" alt="Twitter" />
          </a>
        </div>
      </div>
      {isImpressumVisible && (
        <div className="ContactContainer">
          <div className="hiddenImpressum">
            <h3 style={{ marginBottom: "10px", textAlign: "left" }}>
              SunfloVR LLP
            </h3>
            <p>Andrew Douglas & Ines Hilz</p>
            <p>
              <br></br>
            </p>
            <p>7 Hillpark Grove</p>
            <p> EH4 7AP </p>
            <p>Edinburgh, Scotland</p>
            <p>United Kingdom</p>
          </div>
        </div>
      )}
      <p
        onClick={toggleImpressum}
        style={{
          marginBottom: "20px",
          cursor: "pointer",
          textDecoration: "underline",
        }}
      >
        {isImpressumVisible ? "Hide impressum / legal" : "Impressum / Legal"}
      </p>
    </div>
  );
}
